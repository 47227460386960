<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
      <span class="text-dark fw-bold-400 size14">Toko</span>
    </header-page>

    <b-container fluid v-if="loadingProfile == false && loadingMerchant == false" class="mt-2">

      <b-row>
        <Profile />
      </b-row>
      <Subscribe :get-data="getDataMerchant" :merchant-data="merchantData" />
      <!-- <BankAccounts /> -->

      <!-- Analytic -->
      <b-row class="mt-3 mb-2" v-if="checkPermission('dashboard')">
        <!-- Info Toko -->
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="4">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body class="h-100 d-flex flex-column justify-content-between">
              <div>
                <h2 class="text-dark fw-bold-600 text-uppercase">
                  Info Toko
                </h2>
              </div>
              <div>
                <div class="d-flex">
                  <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                    Affiliate
                  </h6>
                  <h6 class="fw-bold-500 size14 text-dark fw-bold-600 ml-1">
                    {{ summaryStat.affiliate_name || '-' }}
                  </h6>
                </div>
                <div class="d-flex mt-1">
                  <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                    Tanggal Subscribe
                  </h6>
                  <h6 class="fw-bold-500 size14 text-dark fw-bold-600 ml-1">
                    {{ summaryStat.renewal_end_date || '-' }}
                  </h6>
                </div>
              </div>


              <div class="d-flex" style="gap: 1.5rem">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="cabang icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Total Cabang
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.branch_count || '-' }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="user icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Total User
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.user_count || '-' }}
                    </h6>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </b-col>

        <!-- Stok -->
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="4">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body class="h-100 d-flex flex-column justify-content-between">
              <div>
                <h2 class="text-dark fw-bold-600 text-uppercase">
                  STOK
                </h2>
              </div>

              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="cabang icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Total Stok
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.stock_count || '-' }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="user icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Stok Lokasi SKU
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.warehouse_count || '-' }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="cabang icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Stok Opname
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.stock_opname_count || '-' }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="user icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Stok Pemasok
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ summaryStat.supplier_count || '-' }}
                    </h6>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </b-col>

        <!-- Laporan -->
        <b-col class="col-lg-0 mb-md-1" cols="6" md="6" lg="4">
          <b-card class="border-10 p-0 h-100" no-body>
            <b-card-body class="h-100 d-flex flex-column justify-content-between">
              <div>
                <h2 class="text-dark fw-bold-600 text-uppercase">
                  LAPORAN
                </h2>
              </div>

              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="user icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Laba Bersih
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ formatToRupiah(summaryStat.net_profit.total_profit) }}
                    </h6>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="cabang icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Total Pengeluaran
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ formatToRupiah(summaryStat.total_expense.total_expenses) }}
                    </h6>
                  </div>
                </div>
                <div class="d-flex align-items-center" style="gap: 5px;">
                  <div>
                    <b-avatar rounded class="bg-light bg-lighten-dark" size="45">
                      <img src="@/assets/images/icons/User.svg" alt="user icon" />
                    </b-avatar>
                  </div>
                  <div class="d-flex flex-column">
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto">
                      Laba Kotor
                    </h6>
                    <h6 class="fw-bold-400 size14 mb-0 text-dark mr-auto text-center">
                      {{ formatToRupiah(summaryStat.gross_profit.total_profit) }}
                    </h6>
                  </div>
                </div>
              </div>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- Table Top Selling Produk -->
      <b-card v-if="checkPermission('dashboard')">
        <h2 class="mb-2">Top Selling Produk</h2>
        <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items_top_produk"
          :fields="fields_top_product" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
          :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

          <template #cell(uom)="data">
            {{ data.item.uom.name || '-' }}
          </template>

          <template #cell(sku_code)="data">
            {{ data.item.sku_code || '-' }}
          </template>

          <template #cell(warehouse_names)="data">
            {{ data.item.warehouse_names.join(', ') || '-' }}
          </template>

        </b-table>
      </b-card>

      <!-- Table Top Kostumer -->
      <b-card v-if="checkPermission('dashboard')">
        <h2 class="mb-2">Top Kostumer</h2>
        <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items_top_customer"
          :fields="fields_top_customer" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">


          <template #cell(address)="data">
            {{ data.item.address || '-' }}
          </template>

        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BContainer,
  VBToggle,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BAvatar,
  BProgress,
  BTabs,
  BTab,
  BListGroup,
  BListGroupItem,
  BButton,
  BDropdown,
  BDropdownItem,
  BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

import { mapActions } from 'vuex'

import HeaderPage from '@/components/HeaderPage.vue'
import Profile from '@/components/Master/Toko/Profile.vue'
import BankAccounts from '@/components/Master/Toko/BankAccounts.vue'
import Subscribe from '@/components/Master/Toko/Subscribe.vue'
import { checkPermission } from '@/auth/utils'

export default {
  title() {
    return 'Master Toko'
  },
  setup() {
    return {
      checkPermission,
    }
  },
  components: {
    HeaderPage,
    BRow,
    BContainer,
    Profile,
    BankAccounts,
    Subscribe,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
    BProgress,
    BTabs,
    BTab,
    BListGroup,
    BListGroupItem,
    BButton,
    BDropdown,
    BDropdownItem,
    BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      summaryStat: {},
      loadingProfile: true,
      loadingMerchant: true,
      merchantData: {},
      // Table Attribute
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields_top_product: [
        // {
        //   key: 'id', label: 'No', sortable: false
        // },
        { key: 'name', label: 'Nama', sortable: false },
        { key: 'sku_code', label: 'Kode SKU', sortable: false },
        { key: 'uom', label: 'Unit' },
        { key: 'warehouse_names', label: 'Gudang' },
      ],
      fields_top_customer: [
        // {
        //   key: 'id', label: 'No', sortable: false
        // },
        { key: 'name', label: 'Nama', sortable: false },
        { key: 'phone_number', label: 'Handphone', sortable: false },
        { key: 'customer_type', label: 'Jenis Kostumer' },
        { key: 'address', label: 'Alamat' },
      ],
      items_top_produk: [],
      items_top_customer: [],
      items: [
        {
          id: 1111,
          user_store: '17 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
        {
          id: 1112,
          user_store: '18 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
        {
          id: 1113,
          user_store: '18 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
        {
          id: 1114,
          user_store: '18 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
        {
          id: 1115,
          user_store: '18 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
        {
          id: 1116,
          user_store: '18 May 2022',
          status_user: 'Reguler',
          subject: 'Masud Rana',
          status_complete: 1,
          status_payment: 2,
        },
      ],
      status_payment: [{
        1: 'Unpaid', 2: 'Paid', 3: 'Rejected'
      },
      {
        1: 'primary', 2: 'success', 3: 'danger'
      }],
      status_complete: [{
        1: 'Complete', 2: 'Pending', 3: 'Failed'
      },
      {
        1: 'light-success', 2: 'light-warning', 3: 'light-danger'
      }],
    }
  },
  computed: {
  },
  created() {
    this.getDataProfile()
    this.getDataMerchant()
    this.getMerchantStat()
  },
  mounted() {
    this.totalRows = this.items.length
  },
  methods: {
    ...mapActions('profile', ['getMerchant']),
    ...mapActions('profile', ['getProfile']),
    async getDataMerchant() {
      this.loadingMerchant = true
      await this.getMerchant()
        .then(result => {
          this.$store.commit('profile/setMerchant', result.data.data)
          this.merchantData = result.data.data;
          this.loadingMerchant = false
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    async getDataProfile() {
      this.loadingProfile = true
      await this.getProfile()
        .then(result => {
          this.loadingProfile = false
          this.$store.commit('profile/setProfile', result.data.data)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    formatToRupiah(amount) {
      const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    getMerchantStat() {
      this.$http.get('/merchant-stat')
        .then(result => {
          const { top_customers, top_products } = result.data.data
          this.summaryStat = result.data.data

          this.items_top_customer = top_customers
          this.items_top_produk = top_products

        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.master-toko {
  button.bg-white {
    &:focus {
      background-color: transparent !important;
    }
  }
}
</style>
