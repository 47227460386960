<template>
    <b-modal id="modal-subscribe" hide-header hide-footer ok-only ok-title="Accept" centered size="lg" title="Large Modal"
        class="custom__modal--large">
        <div class="section">
            <ModalHeader v-if="step === 1" :left-text="'Kembali'" :middle-text="'Pilih Paket Berlangganan'"
                :is-rigt-column="true" :right-text="'Selanjutnya'" :right-function="nextSubmit" :left-function="prevPage"
                :isDisabled="isNextButtonDisabled" />
            <ModalHeader v-else-if="step === 2" :left-text="'Kembali'" :middle-text="'Pilih Metode Pembayaran'"
                :is-rigt-column="true" :right-text="'Bayar'" :right-function="createSubscription" :left-function="prevPage"
                :isDisabled="isPaymentSelected" />
            <!-- Spinner -->
            <b-col v-if="isLoading == true" class="d-flex justify-content-center mt-5 pt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Loading..." />
            </b-col>
            <b-container v-else class="mt-15">
                <!-- Step 1 -->
                <b-container v-if="step === 1">
                    <b-row class="justify-content-center h-100 bg-white pb-5">
                        <b-col v-for="item in subscription" :key="item.id" cols="10" md="6" lg="3">
                            <b-card
                                class="card__subscription cursor-pointer border-15 bg-secondary text-white text-center mb-1"
                                @click="selectSubscription(item.id)">
                                <div class="discount-tag fw-bold-900 bg-warning" v-if="item.discount_percent === highestDiscountSubscription">
                                    <div>Best Offer</div>
                                </div>
                                <div class="mb-2">{{ item.renewal_name }}</div>
                                <div class="mb-2">{{ item.renewal_description }}</div>
                                <div class="mt-4 size24 fw-bold-600">Hemat {{ item.discount_percent }}%</div>
                                <template #footer>
                                    <div class="strike-through">{{ item.price | formatAmount }}</div>
                                    <div class="mb-2 size22">{{ item.sell_price | formatAmount }}</div>
                                    <div class="d-flex justify-content-center">
                                        <b-form-radio v-model="selectedId" :name="'renewal-option-' + item.id"
                                            :value="item.id" />
                                    </div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-container>
                <!-- Step 2 -->
                <b-container v-if="step === 2">
                    <b-row class="justify-content-center h-100 bg-white pb-5">
                        <b-col cols="10" md="6" lg="3">
                            <b-card class="card__subscription border-15 bg-secondary text-white text-center">
                                <div class="discount-tag fw-bold-900 bg-warning" v-if="getSelectedSubscription('discount_percent') === highestDiscountSubscription">
                                    <div>Best Offer</div>
                                </div>
                                <div class="mb-2">{{ getSelectedSubscription('renewal_name') }}</div>
                                <div class="mb-2">{{ getSelectedSubscription('renewal_description') }}</div>
                                <div class="mt-4 size24 fw-bold-600">Hemat {{ getSelectedSubscription('discount_percent')}}%</div>

                                <template #footer>
                                    <div class="strike-through">{{ getSelectedSubscription('price') | formatAmount }}</div>
                                    <div class="mb-2 size22">{{ getSelectedSubscription('sell_price') |
                                        formatAmount }}</div>
                                </template>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-center bg-white pb-2">
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <b-card class="w-75 cursor-pointer" @click="selectPayment('bca_va')">
                                <div class="d-flex align-items-center justify-content-center">
                                    <b-form-radio v-model="selectedPayment" name="bca_va" value="bca_va" />
                                    <b-img :src="require('/src/assets/images/logo/logo-bca.png')" alt="bca" />
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <b-card class="w-75 cursor-pointer" @click="selectPayment('mandiri_va')">
                                <div class="d-flex align-items-center justify-content-center">
                                    <b-form-radio v-model="selectedPayment" name="mandiri_va" value="mandiri_va" />
                                    <b-img :src="require('/src/assets/images/logo/logo-mandiri.png')" alt="mandiri" />
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="12" md="6" lg="3" class="d-flex justify-content-center mb-3">
                            <b-card class="w-75 cursor-pointer" @click="selectPayment('qr_code')">
                                <div class="d-flex align-items-center justify-content-center">
                                    <b-form-radio v-model="selectedPayment" name="qr_code" value="qr_code" />
                                    <b-img :src="require('/src/assets/images/logo/logo-qris.png')" alt="qris" />
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>

                </b-container>
            </b-container>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal, BContainer, BRow, BCol, BFormGroup, BFormInput, BImg, BCard, BButton, BFormCheckbox, BFormRadio, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import ModalHeader from '@/components/ModalHeader.vue'

export default {
    components: {
        BModal,
        BRow,
        BCol,
        vSelect,
        BImg,
        BCard,
        BButton,
        BFormGroup,
        BFormInput,
        BContainer,
        BFormCheckbox,
        BFormRadio,
        BSpinner,
        ModalHeader,
    },
    data() {
        return {
            step: 1,
            subscription: [],
            selectedId: '',
            selectedPayment: '',
            isLoading: false,
        }
    },
    setup() {
        return {
            checkPermission, successNotification, errorNotification,
        }
    },
    created() {
        this.getItems()
    },
    computed: {
        hasSelectedItem() {
            return this.selectedId !== '';
        },
        isNextButtonDisabled() {
            return !this.hasSelectedItem;
        },
        isPaymentSelected() {
            return !this.selectedPayment;
        },
        highestDiscountSubscription() {
        if (this.subscription.length === 0) {
            return [];
        }

        // Find the subscription with the highest discount_percent
        const highestDiscount = Math.max(
            ...this.subscription.map((item) => item.discount_percent)
        );

        return highestDiscount;
        },
    },
    methods: {
        async getItems() {
            this.$store.dispatch('subscribe/getData')
                .then(result => {
                    this.subscription = result.data.data
                }).catch(err => {
                    console.log(err)
                })
        },
        createSubscription() {
            if (this.step === 2) {
                if (!this.selectedPayment) {
                    errorNotification('Silahkan pilih metode pembayaran terlebih dahulu.');
                    return;
                }

                this.$swal({
                    title: 'Konfirmasi',
                    text: 'Apakah Anda yakin dengan pilihan berlangganan ini?',
                    icon: 'warning',
                    showCancelButton: true,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1',
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.isLoading = true;
                        const payload = {
                            subscription_renewal_id: this.selectedId,
                            payment_method: this.selectedPayment,
                        };
                        this.$store.dispatch('subscribe/postSubscription', { payload })
                            .then(response => {
                                this.$bvModal.hide('modal-subscribe');
                                const uuid = response.data.data.uuid;

                                this.$router.push({
                                    name: 'billing.index',
                                    params: { id: uuid },
                                });
                            })
                            .catch(error => {
                                this.isLoading = false;
                                errorNotification('Error creating subscription.');
                                console.error(error);
                            });
                    }
                });
            }
        },
        selectSubscription(id) {
            this.selectedId = id;
        },
        selectPayment(paymentMethod) {
            this.selectedPayment = paymentMethod;
        },
        nextSubmit() {
            if (this.step === 1) {
                this.step = 2;
            }
        },
        prevPage() {
            if (this.step === 2) {
                this.step = 1;
            } else {
                this.$bvModal.hide('modal-subscribe')
            }
        },
        getSelectedSubscription(propertyName) {
            const selectedRenewal = this.subscription.find(item => item.id === this.selectedId);
            return selectedRenewal ? selectedRenewal[propertyName] : '';
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>